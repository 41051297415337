import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import List from '../../components/Common/List'
import RequestDemo from '../../components/RequestDemo'
import ContactForm from '../../components/contact-form'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './bim-services.module.scss'

const BimServices: React.FC = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title="BIM Construction Software | BIM for Construction Management Software"
        description="Unlock the potential of BIM construction software in SIGNAX. Elevate your construction management with BIM and harness the power of building information modeling. Discover BIM-empowered construction management software for your efficient business workflows."
        keywords="bim construction software, bim in construction management, bim building information modeling, bim in construction, bim for construction management, bim modelling software, building information modeling bim software, building information modelling software"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title="SIGNAX Bim Services"
        colorShadow="dark"
      />
      <Section className={s.section_description}>
        <DescriptionBlock2
          title="Start using BIM right now"
          description={`Digital processes come to\u00A0the\u00A0construction site to\u00A0make it\u00A0better. You can easily outsource these services saving time, money and using the professional expertise of\u00A0SIGNAX team. They will work together with your engineering team and help them learn.`}
          classNameTitle="mb-4"
        />
      </Section>
      <ModuleSection2>
        <ModuleSectionItem2
          title="Planning Phase"
          variantTitle="h2"
          description={`In the planning stage of a project, the design process is crucial as it sets the foundation for the construction phase. At the same time, it's important to ensure that the IT infrastructure is set up to facilitate seamless communication and data management. Quantity takeoff and project structuring are also important aspects that establish a framework for resource allocation and scheduling, which ultimately contribute to the efficient execution of the project.`}
          btnText="Read more"
          onClick={() => navigate(getPath('/planning-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>Read more about our services:</p>
              <List>
                <li>BIM modeling</li>
                <li>Common Data Environment</li>
                <li>BIM model audit</li>
                <li>BIM model classification</li>
                <li>Quantity Take-off</li>
              </List>
            </div>
          }
          columnRightChildren={
            <div className="flex items-center">
              <GatsbyImage
                image={images.image.childImageSharp.gatsbyImageData}
                alt="Common data environment (CDE) setup and implementation"
                title="Common data environment (CDE) setup and implementation"
              />
            </div>
          }
        />
        <ModuleSectionItem2
          title="Execution phase"
          variantTitle="h2"
          description={`During the project's construction stage, progress tracking is essential to ensure tasks are completed on time. Regular QA/QC inspections are conducted to maintain quality standards, while schedule monitoring helps identify deviations and allows for timely adjustments to keep the project on track.`}
          btnText="Read more"
          onClick={() => navigate(getPath('/execution-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>Read more about our services:</p>
              <List>
                <li>Work scheduling (WBS)</li>
                <li>Construction Data integration</li>
                <li>Construction project documentation</li>
                <li>As-built BIM model development</li>
              </List>
            </div>
          }
          columnRightChildren={
            <GatsbyImage
              image={images.image2.childImageSharp.gatsbyImageData}
              alt="On-Site BIM Support/\n BIM Field Coordination"
              title="On-Site BIM Support/\n BIM Field Coordination"
              objectFit="contain"
            />
          }
          reverseElements
        />
        <ModuleSectionItem2
          title="Operational phase"
          variantTitle="h2"
          description={`In the project's operational stage, the focus shifts to the construction project's ongoing maintenance and operation. Digital twins are employed to replicate physical assets virtually, allowing for real-time monitoring and analysis. This enables predictive maintenance, where potential issues can be identified and addressed before they lead to costly disruptions, ensuring optimal performance.`}
          btnText="Read more"
          onClick={() => navigate(getPath('/operational-phase/'))}
          columnLeftChildren={
            <div className={s.lists}>
              <p className={s.text}>Read more about our services:</p>
              <List>
                <li>Digital Twin development</li>
                <li>Asset information storage</li>
                <li>Virtual training</li>
              </List>
            </div>
          }
          columnRightChildren={
            <div className="flex items-center">
              <GatsbyImage
                image={images.image3.childImageSharp.gatsbyImageData}
                alt="BIM Consulting and Management"
                title="BIM Consulting and Management"
              />
            </div>
          }
        />
      </ModuleSection2>
      <RequestDemo
        title={t('Know more')}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default BimServices

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "bim-services/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image: file(relativePath: { eq: "bim-services/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "bim-services/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "bim-services/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
  }
`
